import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/SEO';

const Kontakt = () => (
    <Layout>
        <SEO title="Kontakt" />
        <section className="page-title">
            <div className="container">
                <div className="row">
                    <div className="col-12 contact-title-section">
                        <h1>Kontaktieren Sie uns.</h1>
                        <p className="xmsonormal">
                            <span>
                                Erreichbarkeit. Treten Sie mit uns in Kontakt. Bei Fragen und Problemlösungen stehen wir Ihnen zur Seite.
                                <br />
                            </span>
                            <span>Füllen Sie einfach unten stehendes Formular aus oder kontaktieren Sie uns telefonisch unter </span>
                            <b>
                                <span>0711 – 46 98 777</span>
                            </b>
                            <span>.</span>
                        </p>
                        <p className="xmsonormal">
                            <b>
                                <span>
                                    Montag – Donnerstag: 9:00 – 12:00 &amp; 14:00 – 17:00
                                    <br />
                                    Freitag: 9:00 – 12:00
                                </span>
                            </b>
                        </p>
                    </div>
                </div>
            </div>
        </section>
    </Layout>
);

export default Kontakt;
